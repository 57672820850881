import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { keyframes } from 'styled-components'

import './index.css'
// import logo from './ep.png'
import ugly from './XP Eagle gradient.png'

class TemplateWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isScrolling: false,
      showMenu: false
    }
  }

  componentDidMount () {
    typeof window !== 'undefined' && window.addEventListener('scroll', (e) => this.changeHeader(e))
  }

  componentWillUnmount () {
    typeof window !== 'undefined' && window.removeEventListener('scroll', (e) => this.changeHeader(e))
  }

  changeHeader = (e) => {
    const { scrollTop } = e.target.scrollingElement
    typeof window !== 'undefined' &&
    this.setState({
      isScrolling: scrollTop > window.innerHeight - 59
    })
  }

  toggleMenu = (e) => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }))
  }

  hideMenu = (e) => {
    this.setState({ showMenu: false })
  }

  scroll = (e) => {
    e.preventDefault()
    const el = e.target.href.split('#')[1]
    const { top } = document.querySelector(`#${el}`).getBoundingClientRect()
    this.hideMenu()
    typeof window !== 'undefined' && window.scrollBy({
      top: top - 59,
      left: 0, 
      behavior: 'smooth' 
    })
  }

  scrollTop = (e) => {
    typeof window !== 'undefined' && window.scrollTo({
      top: 0,
      left: 0, 
      behavior: 'smooth' 
    })
  }

  render () {
    const { children } = this.props
    let style = typeof window !== 'undefined' ? { opacity: (1 - (window.scrollY - 200) / 300).toFixed(2) } : { opacity: 1 }

    return (
      <div id="top">
        <Helmet
          title="Execution Partners"
          meta={[
            { name: 'description', content: 'Execution Partners' },
            { name: 'keywords', content: 'Execution Partners' },
            {}
          ]}
        >
          <link rel="icon" href="/icons/favicon-32x32.png" />
          <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192"  href="/icons/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/icons/favicon-96x96.png" />
        </Helmet>
        <Header scrolled={this.state.isScrolling}>
          <div className='flex j-c a-c'>
            <LogoLink onClick={this.scrollTop}>
              <Logo src={ugly} scrolled={this.state.isScrolling} />
              <span style={{ marginTop: '4px' }}>Execution Partners</span>
            </LogoLink>
          </div>
          <Nav scrolled={this.state.isScrolling} open={this.state.showMenu}>
            <Link to='#services' activeStyle={{ color: 'red' }} onClick={this.scroll}>Services</Link>
            <a href='#focus' onClick={this.scroll}>Focus</a>
            <a href='#method' onClick={this.scroll}>Method</a>
            <a href='#industries' onClick={this.scroll}>Industries</a>
            <a href='#clients' onClick={this.scroll}>Clients</a>
            <a href='#people' onClick={this.scroll}>People</a>
            <a href='#contact' onClick={this.scroll}>Contact us</a>
            <CloseButton onClick={this.toggleMenu}><img src={require('./close.png')} alt="close" /></CloseButton>
          </Nav>
          <MenuButton onClick={this.toggleMenu}>
            <Svg width="24" height="24" viewBox="0 0 24 24" scrolled={this.state.isScrolling}>
              <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
            </Svg>
          </MenuButton>
        </Header>
        <Hero />
        <Opacity className='flexv a-c' style={style}>
          <HeroText>
            <HeroLogo src={ugly} />
            Execution Partners
          </HeroText>
          <TagLine>from challenges to results</TagLine>
          <ScrollIcon>
            <img src={require('./icon-down.png')} alt="scroll down" />
          </ScrollIcon>
        </Opacity>
        <Wrapper>
          {children}
        </Wrapper>
      </div>
    )
  }
}

TemplateWrapper.propTypes = {
  children: PropTypes.any
}

export default TemplateWrapper

const Wrapper = styled.div`
  width: 100%;
`

const img = '/greenvillesc-large.jpg'
// const img = require('./greenvillescedited.jpg')

const Hero = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: url(${img});
  // #355e9f
  background-size: cover;
  background-position: center center;
  // mix-blend-mode: luminosity;
  // filter: grayscale(0) brightness(1.3);
  // filter: grayscale(0.9) brightness(1.3);
  filter: grayscale(0.7);
  color: #fff;
`

const HeroText = styled.div`
  position: relative;
  margin: 0;
  font-size: 34px;
  line-height: 1.5em;
  text-align: center;
  text-shadow: 0px 0px 30px rgba(20, 20, 20, 1);

  @media screen and (min-width: 800px) {
    font-size: 44px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 70px;
  }
`

const HeroLogo = styled.img`
  position: absolute;
  left: 50%;
  top: -100px;
  width: 100px;
  transform: translateX(-50%);
  z-index: -1;
  @media screen and (min-width: 800px) {
    left: -130px;
    top: -65px;
    width: 170px;
    transform: none;
  }
`

const TagLine = styled.div`
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.5em;
  @media screen and (min-width: 800px) {
    font-size: 24px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 30px;
  }
`

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(200, 200 ,200 ,0.2);
  // box-shadow: inset 0 60px 60px -30px rgba(0, 0, 0, 0.3);
  ${props => props.scrolled ? `
    background: rgba(255,255,255,1);
    color: #333;
    ` : `
    background: rgba(255,255,255,0);
    color: #fff;
  `}
  z-index: 20;
  padding: 0 16px;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 970px) {
    padding: 0 30px;
  }
`

const Logo = styled.img`
  width: 40px;
  margin: -10px 0 0;
  ${props => props.scrolled ? `
    filter: brightness(1);
    ` : `
    filter: brightness(100);
  `}
`

const LogoLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  cursor: pointer;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;

  position: fixed;
  background: #333;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  transform: translateX(-100%);
  transition: all 0.2s cubic-bezier(.7,.07,.74,.97);

  ${props => props.open ? `
    transform: translateX(0%);
    color: #fff !important;
  `:`
    transform: translateX(-100%);
  `}

  @media screen and (min-width: 970px) {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    flex-direction: row;
    overflow: visible;
    background: transparent;
    transform: none;
    ${props => props.scrolled ? `
      color: #333;

      svg {
        fill: #fff;
      }
    ` : `
      color: #fff;
      
      svg {
        fill: #333;
      }
    `}
  }

  a {
    text-decoration: none;
    line-height: 59px;
    padding: 0 20px;
    transition: all 0.3s ease-in-out;
    color: inherit;
    padding-top: 4px;

    &:hover {
      // background: rgba(255, 255, 255, 0.25);
      box-shadow: inset 0 -4px 0px -3px #4a80d4;
      // background: transparent !important;
      color: #4a80d4;
    }
  }
`

const Opacity = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
`

const anim = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
  transform: translateY(46px);
}
`

const ScrollIcon = styled.div`
  position: absolute;
  left: 50%;
  width: 24px;
  height: 24px;
  bottom: 50px;

  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: ${anim};
`

const MenuButton = styled.div`
  justify-content: center;
  align-content: center;
  align-self: center;
  cursor: pointer;
  padding: 10px;
  
  @media screen and (min-width: 970px) {
    display: none;
  }

  svg {
    margin: 0;
    justify-self: center;
    align-self: center;
    display: flex;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 16px;
  justify-content: center;
  align-content: center;
  align-self: center;
  cursor: pointer;
  padding: 10px;

  @media screen and (min-width: 970px) {
    display: none;
  }

  img {
    margin: 0;
    justify-self: center;
    align-self: center;
    display: flex;
  }
`

const Svg = styled.svg`
  transition: all 0.3s ease-in-out;
  ${props => props.scrolled ? `
    fill: #333;
  `:`
    fill: #fff;
  `}
`