import React from 'react'
import { compose } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
// import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/layout"
import Contact from './form'

// import Execution from './slides/execution'
// import Focus from './slides/focus'

// import { Wrapper, Background, Slice, Triple, H2, H3, H4, Tag, Visual, Visual1, Quote, Author, Clients, Client, Face } from './components'

const logos = [
  {
    url: require('./clients/1.png'),
    style: {}
  },
  {
    url: require('./clients/2.png'),
    style: { maxWidth: '100px' }
  },
  {
    url: require('./clients/3.png'),
    style: {}
  },
  {
    url: require('./clients/4.png'),
    style: {}
  },
  {
    url: require('./clients/5.png'),
    style: {}
  },
  {
    url: require('./clients/6.png'),
    style: {}
  },
  {
    url: require('./clients/7.jpg'),
    style: { maxWidth: '200px', maxHeight: '130px' }
  },
  {
    url: require('./clients/8.jpg'),
    style: {}
  },
  {
    url: require('./clients/9.jpg'),
    style: {}
  },
  {
    url: require('./clients/10.png'),
    style: {}
  },
  {
    url: require('./clients/11.png'),
    style: {}
  },
  {
    url: require('./clients/12.png'),
    style: {}
  }
]

export default class IndexPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      visibility: [0, 0, 0, 0, 0, 0]
    }

    this.sections = []
  }

  componentDidMount () {

  }

  toggleContent = (e, index) => {
    e.persist()
    this.setState(prevState => {
      const visibility = new Array(6).fill(false)
      visibility[index] = !prevState.visibility[index]
      return {
        visibility
      }
    })

    // setTimeout(() => {
    //   typeof window !== 'undefined' && window.scrollBy({
    //     top: e.target.getBoundingClientRect().top - 58,
    //     left: 0,
    //     behavior: 'smooth'
    //   })
    // }, 500)
  }

  render () {
    return (
      <Layout>
        <Wrapper>
          <Slice id='execution' name='execution'>
            <H2>Execution</H2>
            <p>Are your people, processes or systems struggling to keep up with changes in the marketplace?</p>

            <p>Do you need dramatic performance improvements to address customer requirements, competitive
            pressures, or shareholder expectations?</p>

            <p>Are your process improvement initiatives not delivering the expected returns?</p>

            <p>Are you struggling to manage change and make improvements stick?</p>

            <p>Are you lacking the internal bandwidth to properly manage a complex or high-risk project with focus,
            accountability, and results?</p>

            <p>Could an outside perspective or more methodical approach be helpful in addressing and solving some
            challenging and persistent problem once and for all?</p>

            <H4>Could speed and quality of execution be the differentiator needed to set your company apart?</H4>
          </Slice>
          <Background large>
            <Visual src={require('./visuals/2. The Execution Challenge.png')} style={{
              width: '100%',
              height: 'auto',
              margin: '20px 0 0',
              maxWidth: '600px'
            }} />
          </Background>
          <Slice id='services'>
            <H2>Services</H2>
            <p>Execution Partners helps companies improve performance & achieve objectives through focused
            execution support, and the implementation of efficient and reliable "execution systems"</p>

            <p>We partner with our clients to solve their most challenging problems, streamline critical processes, and
            execute complex projects and programs, while carefully managing change and building internal
            capabilities.</p>

            <p>We provide expert guidance, best practices, effective tools, and hands-on support, enabling teams to
            achieve better results faster, cheaper, and more sustainably through improved clarity, alignment,
            transparency and accountability.</p>

            <p>Wherever possible, we strive to implement efficient and reliable “execution systems” to enable significant and sustainable performance improvement, through the alignment of people, processes, and technology.</p>

            <H4>Execution Partners - your shortest and surest path from challenges to results.</H4>
          </Slice>
          <Background large id='focus'>
            <Overflow>
              <Visual src={require('./visuals/3. PRO3 Execution Systems.png')} style={{
                width: '100%',
                height: 'auto',
                margin: 0,
                maxWidth: '800px'
              }} />
            </Overflow>
          </Background>
          <Slice>
            <H2>Focus</H2>

            <div style={{ marginTop: '-20px' }}>
              <AccordeonTitle
                open={this.state.visibility[0]}
                onClick={(e) => this.toggleContent(e, 0)}
                ref={section => { this.sections[0] = section }}
              >Project Management</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[0]}>
                  <p>Projects are a part of life in business. They’re a powerful approach to help us get from where we are to where we need to be. Sound project management can deliver the desired results in the shortest time and most efficient way. Unfortunately, due to the temporary nature, wide range of challenges, and day-to-day operational responsibilities and priorities, many companies struggle to execute projects consistently. As a result, projects tend to place a heavy burden on an organization, taking up too much time and resources, dragging on for too long, and often ending up dying a silent death - or failing even more painfully.</p>
                  <p>Execution Partners can provide expert advice, best practices, tools, training, and coaching, but can also take a more direct role, from providing basic project management support, to taking the lead in the overall end-to-end project management. Using our proven methodology and powerful tools, we have supported projects large and small, addressing many different challenges, in very different industries, ranging from construction project management to systems implementation to strategy execution.</p>
                  <p>Applying our unique methodology, we assist our clients in achieving improved clarity and alignment on purpose, objectives, and approach. We implement and help sustain better project structure and coordination, and unlock the focus and accountability necessary to deliver the desired progress and results. Through expert coaching, hands-on support, or full-blown project leadership, we help deliver projects on time, complete, according to specifications, and -most importantly- with the expected results and return on investment.</p>
                </AccordeonContent>
              </AccordeonWrapper>

              <AccordeonTitle
                open={this.state.visibility[1]}
                onClick={(e) => this.toggleContent(e, 1)}
                ref={section => { this.sections[1] = section }}
              >Process Improvement</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[1]}>
                  <p>In essence, companies represent a collection of business processes that create and deliver value to their customers. Unfortunately, processes have a tendency to deteriorate and increase in complexity over time. For that reason, it is essential to keep maintaining and improving especially those processes most important to the customer experience, and those processes most critical to an organization’s overall competitiveness and profitability.</p>
                  <p>Execution Partners can help identify and prioritize the processes most in need of improvement. We can help measure current performance levels, define realistic performance targets, and assess the dollar value of improved performance. Together with our client’s team of subject matter experts, we can map out and visualize the process in as much detail as needed, including any interfaces, hand-offs, and system support involved.</p>
                  <p>Effective process mapping allows us to identify and assess obstacles, inefficiencies, and vulnerabilities. Based on a clear understanding of customer expectations, stakeholder concerns, and the broader context of related processes and systems, we can then develop and implement a focused action plan for improvement, in order to streamline the process, address any waste and vulnerabilities, improve system support, and achieve the desired improvements in performance.</p>
                  <p>Finally, implementing proper process controls and feedback loops, defining clear roles and responsibilities, and paying special attention to proper change management practices, all help to ensure optimal adoption and sustainable results.</p>
                </AccordeonContent>
              </AccordeonWrapper>

              <AccordeonTitle
                open={this.state.visibility[2]}
                onClick={(e) => this.toggleContent(e, 2)}
                ref={section => { this.sections[2] = section }}
              >Problem Solving</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[2]}>
                  <p>Effective problem solving is critical for every organization. Improving processes, managing projects, and simply running the day-to-day operations, would all be impossible without continuous problem solving. Problems come in many different shapes and sizes, including issues, risks, and opportunities of different types and levels of complexity. Whether we are trying to resolve an actual issue, mitigate a risk, or leverage an opportunity, it’s important to use an appropriate problem solving approach.</p>
                  <p>There are many different problem solving strategies, methodologies and techniques, ranging from very simple to rather sophisticated. Execution Partners helps its clients select and apply the right problem solving approach for the specific challenge being addressed. More often than not, simple is better, and a touch of human ingenuity beats statistical analyses. Essential is to clearly define the problem, agree on the objectives and success metrics, understand the cause-effect relationships, develop and assess a range of feasible options, and lay out a practical action plan. We can provide effective facilitation, bringing together a balanced set of stakeholders and subject matter experts, creating a productive environment, and asking focused questions. By using the Socratic approach, scientific method, and design thinking techniques, we stimulate creative thinking and organizational learning.</p>
                  <p>Execution Partners can provide a fresh, outside perspective, help a team look at things from new angles, and bring ideas from different industries and environments. Of course, problem solving -for all but the simplest problems- should lead to action planning, and be followed by focused execution.</p>
                </AccordeonContent>
              </AccordeonWrapper>
            </div>

            <p style={{ margin: '0px', lineHeight: '10px' }}>&nbsp;</p>

            <div>
              <AccordeonTitle
                open={this.state.visibility[3]}
                onClick={(e) => this.toggleContent(e, 3)}
                ref={section => { this.sections[3] = section }}
              >Project Execution Systems</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[3]}>
                  <p>More and more companies recognize the critical importance of in-house project execution ability. They call on Execution Partners to help them put in place the people, processes, and technology - a “project execution system” - needed to execute projects quickly, efficiently, and successfully – through their own people.</p>
                  <p>It’s important to select the right project management methodology, or set of methodologies, to effectively tackle projects of different types and sizes. Execution Partners can help identify the best methodologies, or blend of methodologies, and adapt them to our clients’ specific situation and needs. Based on the chosen methodologies, we can then help select the technologies and tools best suited to support them. We are technology agnostic and can help set up effective project management processes based on a wide range of tools, from “MS Office project management” to sophisticated total solutions, both cloud-based and on-premise.</p>
                  <p>Most importantly, we can help educate, train, and coach our clients’ in-house project management talent, and help define and implement the right organizational structure to spread, nurture, and develop effective project management practices throughout the organization.</p>
                  <p>Ultimately, an effective Project Execution System enables people to combine project managerial tasks and activities with day-to-day operational activities and responsibilities, helping them stay on top of it all, and succeed in both. The systematized capability to consistently and reliably deliver on complex projects can provide a significant and sustainable competitive advantage.</p>
                </AccordeonContent>
              </AccordeonWrapper>

              <AccordeonTitle
                open={this.state.visibility[4]}
                onClick={(e) => this.toggleContent(e, 4)}
                ref={section => { this.sections[4] = section }}
              >Manufacturing Execution Systems</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[4]}>
                <p>Manufacturing Execution Systems (MES) are hardware and software solutions that support efficient and effective Manufacturing Operations Management by “bridging ERP and PLC”. MES forms the foundation for “Smart Manufacturing” or “Industry 4.0” and enables sustainable Operational Excellence by standardizing, integrating, and automating critical manufacturing processes, while empowering people at all levels with access to relevant and accurate information needed to operate and improve on a daily basis.</p>
                <p>Execution Partners helps its clients understand and navigate the complex and ever-evolving MES landscape. Our unique strength and value-added is in helping leadership teams gain clarity and alignment on the business needs, priorities, future state vision, expected benefits, and return on investment.</p>
                <p>Except for a partnership with Proceedix* focused on digital work instructions, we are not tied to any specific platform or vendor, which allows us to help our clients find the platform or best-of-breed solution that works best for their specific situation. Our focus is on needs assessment & prioritization, cost/benefit analysis, functional spec creation, solution search & selection, and implementation support. We leave any technical design, development, and integration up to the experts.</p>
                <p>*Proceedix is a game-changing platform for the digital transformation of enterprise work flows by executing instructions and inspections with mobile and wearable technology, including the brand-new Google Glass Enterprise Edition. Based on our experience in process improvement and system implementation, Proceedix has chosen Execution Partners as its strategic implementation partner for the USA.</p>
                </AccordeonContent>
              </AccordeonWrapper>

              <AccordeonTitle
                open={this.state.visibility[5]}
                onClick={(e) => this.toggleContent(e, 5)}
                ref={section => { this.sections[5] = section }}
                style={{ paddingBottom: 0 }}
              >Business Execution Systems</AccordeonTitle>
              <AccordeonWrapper>
                <AccordeonContent open={this.state.visibility[5]}>
                <div style={{ height: '20px' }} />
                <p>Many organizations have basic quality management systems in place, such as ISO, or industry-specific quality systems such as TS16949, AS9100TS, or GMP. But the scope and effectiveness of such systems is typically rather limited. Execution Partners helps organizations with the design and implementation of customized Business Execution Systems, based on the Baldrige Performance Excellence framework and EFQM Excellence Model, aligning and integrating the people, processes, and technology needed to run their business successfully and consistently, from enterprise vision to shopfloor operations.</p>
                <p>Well-designed and properly implemented Business Execution Systems result in focused and motivated people, running efficient and reliable processes, supported by effective technology and tools. Not only do they allow for much higher levels of productivity, they also enable the responsiveness, flexibility, and agility needed to remain competitive and profitable in the marketplace of today - and tomorrow.</p>
                </AccordeonContent>
              </AccordeonWrapper>
            </div>

          </Slice>
          <Background large id='method' style={{ height: 'auto' }}>
            <Overflow>
              <Visual src={require('./visuals/4. XP Method.png')} style={{
                width: '100%',
                height: 'auto',
                margin: 0,
                maxWidth: '1140px',
                minWidth: '800px'
              }} />
            </Overflow>
          </Background>

          <Slice>
            <H2>Method</H2>
            <p>Ultimately, it’s all about achieving results that are significant and sustainable. Our method consists of three essential parts that drive each other: a set of efficient and reliable enabler processes supports a focused problem solving approach, which enables the kind of flawless execution that delivers results quickly, efficiently, and sustainably.</p>

            <p>Our enabler processes represent the typical project management aspects (or “knowledge areas” in PMI terminology) that are crucial for focused execution: identifying and engaging all relevant stakeholders, setting up effective communication, agreeing on a clear scope, objectives, and success metrics, developing a budget and schedule, staying on top of all project tasks and project related information, managing risks and change, and improving overall quality and integration.</p>

            <p>Our problem solving approach ensures clarity and alignment on definitions and objectives, establishes a clear baseline and targets, defines expected ROI, identifies causes and options, develops and executes a feasible action plan, validates the results, and provides the documentation, training, and support needed to enable continued learning and improvement.</p>

            <p>Together, our enabler processes and problem solving method significantly improve clarity, alignment, planning, and organization. This allows for focused and well-coordinated execution, with high levels of transparency and accountability, resulting in accelerated progress, faster and deeper learning, and much more sustainable results.</p>
          </Slice>
          <Background large id='industries'>
            <Visual2 src={require('./visuals/5. Playing Field.png')} style={{
                width: '100%',
                height: 'auto',
                maxWidth: '900px'
              }} />
          </Background>

          <Slice>
            <H2>Industries</H2>
            <p>Our services and methodologies are applicable to very diverse challenges and environments. We’ve worked in industries as varied as automotive, healthcare, and construction. But our main focus is manufacturing. We’ve done projects in many different manufacturing environments, including automotive, plastics extrusion, injection molding, precision metals, steel fabrication, equipment manufacturing, printing, flexible packaging, and cable manufacturing.</p>

            <p>We’ve helped companies with strategy formulation, strategy execution, business process reengineering, customer experience redesign, software and hardware selection & implementation, strategic sourcing, supplier development, supply chain optimization, operational excellence program development & implementation, business development, startup acceleration, operational assessments, and turnarounds.</p>

            <p>In short, we are at our best dealing with challenging problems, processes, and projects. And we have a passion for designing -or refining- “execution systems” that enable sustainable results.</p>
          </Slice>
          <Background inverse padding>
            <Quote style={{ marginTop: '20px' }}>Giving the order might be the easiest part. Execution is the real game.</Quote>
            <Author>Russel L. Honoré</Author>
          </Background>

          <Slice id='clients'>
            <H2>Clients</H2>
            <Clients>
              {logos.map((logo, index) => {
                return <Client key={`logo:${index}`} src={logo.url} style={logo.style} />
              })}
            </Clients>
            <Small>(representative sample only, and respecting any clients who -as a policy- don’t allow publication of their logo)</Small>
          </Slice>

          <Slice inverse>
            <Quote>We have been extremely pleased with their guidance, support, quality of work, and overall contribution so far. Their very methodical and thorough approach has helped bring the necessary structure and focus to some of our most strategic initiatives. They are smart, hardworking, and very effective at finding solutions and getting things done. Most importantly: they connect well with people and have managed to get different parties aligned on sometimes difficult subjects. I can highly recommend Execution Partners to anyone needing a fresh perspective or focused execution support.</Quote>
            <Author>Kurt Dallas, Executive Vice President Cable & Connectivity, AFL</Author>
          </Slice>
          <Slice id='people'>
            <H2>People</H2>
            <Triple column>
              <div>
                <Face src={require('./people/stijn.png')} />
                <div>
                  <H3>Stijn Van de Velde</H3>
                  <p>
                  Managing Partner<br />
                  d: +1 (877) 773 9328 x700<br />
                  e: <a href='mailto:stijn@executionpartners.us'>stijn@executionpartners.us</a><br />
                  l: <a href='https://www.linkedin.com/in/stijnjvandevelde/' target='blank'>find Stijn on LinkedIn</a></p>
                </div>
              </div>
              <div>
                <Face src={require('./people/aubrie.jpg')} />
                <div>
                  <H3>Aubrie Street</H3>
                  <p>
                  Founding Partner<br />
                  d: +1 (877) 773 9328 x701<br />
                  e: <a href='mailto:aubrie@executionpartners.us'>aubrie@executionpartners.us</a><br />
                  l: <a href='https://www.linkedin.com/in/aubrie-street-b3ab587a/' target='blank'>find Aubrie on LinkedIn</a></p>
                </div>
              </div>
              <div>
                <Face src={require('./people/joseph.jpg')} />
                <div>
                  <H3>Joseph Etris</H3>
                  <p>
                  Partner<br />
                  d: +1 (877) 773 9328 x702<br />
                  e: <a href='mailto:joseph@executionpartners.us'>joseph@executionpartners.us</a><br />
                  l: <a href='https://www.linkedin.com/in/josephetris/' target='blank'>find Joseph on LinkedIn</a></p>
                </div>
              </div>
            </Triple>
          </Slice>
          <Background inverse>
            <Visual src={require('./visuals/6. Exe-cu-tion.png')} style={{
              width: '100%',
              height: 'auto',
              margin: 0,
              maxWidth: '600px'
            }} />
          </Background>
          <Slice id='contact' style={{ minHeight: 'calc(100vh - 146px)' }}>
            <H2>Contact</H2>
            <Flex>
              <div>
                <div>
                  <p>
                  Execution Partners LLC<br />
                  33 Market Point Dr<br />
                  Greenville, SC 29607<br />
                  Office: +1 (877) 773 9328<br />
                  <a href='mailto:info@executionpartners.us'>info@executionpartners.us</a>
                  </p>
                </div>
                <p>&nbsp;</p>
                <MapWithAMarker
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA0-wRqCiYq9D40V8gwsBx-p0wnSxNtC5A"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `300px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
              <div>
                <Contact />
              </div>
            </Flex>
          </Slice>
          <Copy>Copyright Execution Partners LLC 2017&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;All Rights Reserved</Copy>
        </Wrapper>
      </Layout>
    )
  }
}

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 34.821889, lng: -82.302172 }}
  >
    <Marker
      position={{ lat: 34.821889, lng: -82.302172 }}
    />
  </GoogleMap>
)

class Background extends React.Component {
  constructor (props) {
    super(props)
    console.log(props)
  }

  componentDidMount () {

  }

  render () {
    return <BackgroundSlice {...this.props}>
      {this.props.children}
    </BackgroundSlice>
  }
}

const Wrapper = styled.div`
  position: relative;
`

const Slice = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;

  @media screen and (min-width: 970px) {
    padding: 70px 20px;
  }

  ${props => props.inverse ? `
    background: #333;
    color: #fff;
  ` : `
    background: #fff;
  `}

  @media screen and (min-width: 1200px) {
    // padding: 70px calc(50% - 570px);
    padding: 70px calc(50% - 420px);
  }
`

const img = '/bg.jpg'
// const img = require('../layouts/greenvillesc2_mini.jpg')
const BackgroundSlice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #000;
  height: auto;
  min-height: 150px;
  overflow: hidden;
  background: #355e9f url(${img});
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom center;

  ${props => props.padding && `padding: 20px;`}

  @media screen and (min-width: 800px) {
    padding: 40px 0;
  }

  // &:after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(${img});
  //   background-size: cover;
  //   background-attachment: fixed;
  //   background-position: bottom center;
  //   z-index: 0;
  //   opacity: 0.8;
  //   filter: blur(1px) grayscale(0.7);
  //   margin: 0; // -10px -50px -10px -50px;
  //   opacity: 0.5;
  // }

  H2 {
    color: #fff;
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    padding-bottom: 20px;
    padding-top: 40px;
  }
`

const H2 = styled.h2`
  font-family: 'Raleway';
  font-weight: 100;
  font-size: 40px;
  color: #ccc;

  @media screen and (min-width: 970px) {
    font-size: 50px;
  }

  @media screen and (min-width: 1440px) {
    position: absolute;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    top: 70px;
    // left: calc(50% - 680px);
    left: calc(50% - 570px);
  }
`

const H3 = styled.h3`
  color: #345c9b;
  font-size: 20px;
  font-weight: 100;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  padding-bottom: 10px;
  margin-top: 0;
`

const H4 = styled.h4`
  font-size: 18px;
  margin: 0;
`

const Triple = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.column && `
  flex-direction: column;
  `}

  div {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 960px) {
    ${props => props.column ? `
    flex-direction: column;
    ` : `
    flex-direction: row;
    `}
    > div {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 970px) {
    > div {
      width: 50%;

      + div {
        margin-left: 40px;
      }
    }
  }


  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
`

// const Tag = styled.div`
//   color: #fff;
//   font-size: 24px;
//   padding: 20px 0;
//   margin: 0 auto;
//   text-align: left;
//   font-style: italic;
//   max-width: 740px;
//   line-height: 1.3em;
//   // text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
//   position: relative;
//   z-index: 10;
//   text-align: center;

//   @media screen and (min-width: 700px) {
//     font-size: 34px;
//   }
// `

const AccordeonTitle = styled.div`
  padding: 20px 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  // &:first-child {
  //   padding-top: 0;
  // }

  @media screen and (min-width: 960px) {
    padding: 20px 0;
    font-size: 20px;
  }

  &:before {
    content: "+";
    display: inline-block;
    margin-right: 10px;
    transition: all 0.15s ease-in-out;
    vertical-align: bottom;
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 20px;
    vertical-align: top;
    float: left;
    ${props => props.open ? `
    transform: rotate(45deg);
    ` : `
    transform: rotate(0deg);
    `}
  }

  ${props => props.open && `
    color: #355e9f;
  `}
  `

  const AccordeonWrapper = styled.div`
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);

  &:last-child {
    border-bottom: 0px;
  }

  @media screen and (min-width: 960px) {
    // padding: 0 40px;
  }
`

const AccordeonContent = styled.div`
  height: auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  ${props => props.open ? `
    max-height: 100vh;
  ` : `
    max-height: 0;
  `}

  p:last-child {
    margin-bottom: 20px;
  }
`

const Quote = styled.p`
  position: relative;
  padding: 20px 0;
  margin: 0 auto;
  font-style: italic;
  font-size: 18px;
  line-height: 1.5em;
  z-index: 10;
  color: #fff;

  @media screen and (min-width: 960px) {
    font-size: 24px;
  }

  &:before {
    content: "“";
    display: inline-block;
    position: absolute;
    left: -60px;
    top: 90px;
    font-size: 400px;
    color: #444;
    z-index: -1;
    font-style: normal;
    font-family: serif;
    color: rgba(200, 200, 200, 0.4);
  }
`

const Author = styled.p`
  color: #eee;
  text-align: right;
  margin: 0 auto;
`

const Clients = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
`

const Client = styled.img`
  max-width: 150px;
  max-height: 80px;
  filter: grayscale(1);
  vertical-align: middle;

  width: 40%;
  margin: 10px;
  flex: 0 0 auto;

  @media screen and (min-width: 960px) {
    width: auto;
    margin: 0 20px 30px;
  }

  &:hover {
    filter: none;
  }
`

// const Visual1 = styled.img`
//   position: relative;
//   z-index: 5;

//   @media screen and (min-width: 960px) {
//     width: 30%
//   }
// `

const Visual2 = styled.img`
  position: relative;
  z-index: 5;
  margin: 0;

  @media screen and (min-width: 960px) {
    margin: -50px 0;
  }
`

const Visual = styled.img`
  position: relative;
  z-index: 5;
`

const Face = styled.img`
  overflow: hidden;
  width: 180px;
  margin: 0;
  margin-right: 20px;
  filter: grayscale(1);
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: grayscale(0);
  }
`

const Copy = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  text-align: center;
  background: #333;
  color: #fff;
`

const Overflow = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;

  @media screen and (min-width: 800px) {
    justify-content: center;
    align-items: center;
    padding: 1px 0;
  }
`

const Small = styled.p`
  color: #ccc;
  font-size: 14px;
`